import logo from "./logo.svg";
import CoinApp from "./components/CoinApp";
import { BottomNavigation, CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { useState, useEffect } from "react";
import BotQR from './images/botQR.png'
import axios from "axios";
import env from "react-dotenv";
import "./App.css";
import "./css/customStyle.css";
import './index.css'
import GoogleTrans from "./components/GoogleTranslator/Translator";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Mates from "./pages/Mates";
import BottomNavbar from './components/BottomNavbar'
import Earn from "./components/Earn";
import Boost from "./components/Boost";
import Stats from "./components/Stats";
import Modal from "./components/DailyReward"
import { useDispatch } from "react-redux";
import { init, setData } from "./apis/index";

import { useSelector } from 'react-redux';



const theme = createTheme();
const telApp = window.Telegram.WebApp;
const isPhone = window.innerWidth < 600;


function App() {

  
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.user.userId);
  // const userStatus = useSelector((state) => state.user.userData);
  console.log("userData seprate id =======>", userId);
  useEffect(() => {
    dispatch(init())
  }, [])



  return (
    <div>
      {/* <GoogleTrans/>  */}
      {/* {isPhone && isTelegramMiniApp ? (     */}
      {/* <ThemeProvider theme={theme}>
          <CoinApp
            userData={userData}
            // profileUrl={"./images/blocsys.png"}
            telApp={telApp}
            userId={userData.id}
            pointCount={pointCount}
            setPointCount={setPointCount}
            miningInfo={miningInfo}
            setMiningInfo={setMiningInfo}
          />
        </ThemeProvider> */}
      {/* ) : (      */}

      {/* <div style={{ height: "110vh" }}>
         
        <h3
            style={{
              textAlign: "center",
              background: "rgb(216 215 215 / 42%)",
              display: "inline-flex",
              padding: "20px",
              marginTop: "40vh",
              borderRadius: "20px",
            }}
          >
            You need open with telegram bot!
          </h3> 
          <div style={{display : "flex", justifyContent: "center", alignItems : "center" , flexDirection : "column", background : "transparnent"}}>
          <img src={BotQR} width={200} height={200} /> 
          </div> 
        
          <h3> 
             <a
              href={`https://t.me/${process.env.REACT_APP_TELEGRAM_BOT_USER_NAME}`}
              style={{ textDecoration: "none", color: "darkmagenta" }}
            >
              <img
                style={{ verticalAlign: "middle", marginBottom: "16px" }}
                width="70"
                height="70"
                src="https://img.icons8.com/3d-fluency/94/robot-1.png"
                alt="robot-1"
              />
              <span> Go to BlocsysBot </span>
            </a>
          </h3>   
        </div> */}
      {/* )}    */}
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" exact element={<CoinApp />}></Route>
            <Route path="/mates" element={<Mates />}></Route>
            <Route path="/earn" element={<Earn />}></Route>
            <Route path="/boost" element={<Boost />}></Route>
            <Route path="/stats" element={<Stats />}></Route>
            <Route path="/daily-reward" element={<Modal />}></Route>


          </Routes>
          <BottomNavbar />


        </BrowserRouter>
      </ThemeProvider>


    </div>
  );
}

export default App;
