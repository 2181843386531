
import { setUserData, setUserId } from "../store/slices/userSlice";

import axios from "axios";








export function init() {
    return async (dispatch) => {
        const search = window.Telegram.WebApp.initData;
        let data = null;
        if (search) {
            const converted = JSON.parse(
                '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
                function (key, value) {
                    return key === "" ? value : decodeURIComponent(value);
                }
            );
            data = JSON.parse(converted.user);
            console.log("data", data);
        }
        if (data) {
            dispatch(setUserId(data.id))
        }
    }

}



export function setData(userId) {
    return async (dispatch) => {

        try {
            if (!userId) {
                console.log("user id not found");
                return
            }
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/user/${userId}`);
            dispatch(setUserData(response.data));
        } catch (error) {
            console.error("Error fetching user data:", error);
            // Handle errors, e.g., show an error message
        }
    }
}



