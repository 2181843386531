// import React, { useEffect, useState } from 'react';
// import { Modal, Backdrop, Fade, Typography, Grid, Paper, Box, Button, TextField } from '@mui/material';
// import { styled, keyframes } from '@mui/system';
// import CloseIcon from '@mui/icons-material/Close';
// import airdropMain from '../images/airdropMain.png'
// import taskEx from '../images/taskEx.svg'
// import axios from 'axios';
// import CheckIcon from '@mui/icons-material/Check';
// import { message } from 'antd';
// // import { getAddressForAsset } from "klink-contract-details";

// const slideIn = keyframes`
//   from {
//     transform: translateY(100px);
//     opacity: 0;
//   }
//   to {
//     transform: translateY(0);
//     opacity: 1;
//   }
// `;

// const slideOut = keyframes`
//   from {
//     transform: translateY(0);
//     opacity: 1;
//   }
//   to {
//     transform: translateY(100px);
//     opacity: 0;
//   }
// `;

// const AnimatedDiv = styled('div')(({ open }) => ({
//     animation: `${open ? slideIn : slideOut} 0.5s`,
// }));

// const AirdropModal = ({ open, handleClose, userData, task }) => {
//     const [walletAddress, setWalletAddress] = useState('');
//     const [showInput, setShowInput] = useState(true);
//     const [timeLeft, setTimeLeft] = useState(60); // 3 minutes = 180 seconds
//     const [showClaimButton, setShowClaimButton] = useState(false);
//     const [points, setPoints] = useState(0);
//     const [id, setId] = useState('')
//     useEffect(() => {
//         if (timeLeft === 0) {
//             setShowClaimButton(true);
//         } else if (timeLeft > 0 && !showInput) {
//             const timerId = setInterval(() => {
//                 setTimeLeft((prev) => prev - 1);
//             }, 1000);
//             return () => clearInterval(timerId);
//         }
//     }, [timeLeft, showInput]);

//     const handleSubmit = () => {
//         setShowInput(false);
//         setShowClaimButton(false);
//         setTimeLeft(180); // reset timer to 3 minutes
//     };

//     const formatTime = (seconds) => {
//         const minutes = Math.floor(seconds / 60);
//         const secs = seconds % 60;
//         return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
//     };

//     useEffect(() => {
//         fetchData();
//     }, [userData]);

//     const fetchData = async () => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/${userData.id}`);
//             const data = response.data;
//             //   console.log('Full response data:', data);

//             setPoints(data.points);
//             setId(data.id);
//         } catch (error) {
//             console.error('Error fetching data:', error);
//             // or any default value
//         }
//     };

//     const submitWalletAddress = async (id) => {
//         try {
//             const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/add-wallet-address`, {
//                 userId: userData.id,
//                 userWalletAddress: walletAddress,
//             });

//             message.success('Wallet address submitted successfully');

//         } catch (error) {
//             console.error('Error submitting wallet address:', error);
//         }
//     };

//     const handleWithdraw = async (id) => {
//         const url = `${process.env.REACT_APP_API_URL}/api/v1/withdraw`;
//         const requestBody = {
//             userId: userData.id,
//             userAddress: walletAddress,
//             points: points,
//         };

//         try {
//             const response = await axios.post(url, requestBody, {
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//             });


//             // Handle successful response here (e.g., update state, display message)
//         } catch (error) {
//             console.error('Error during withdraw:', error);
//             // Handle error here (e.g., display error message)
//         }
//     };

//     return (
//         <Modal
//             open={open}
//             onClose={handleClose}
//             aria-labelledby="modal-title"
//             aria-describedby="modal-description"
//             closeAfterTransition
//             // BackdropComponent={Backdrop}
//             BackdropProps={{
//                 timeout: 500,
//             }}

//         >
//             <Fade in={open}>
//                 <AnimatedDiv open={open} style={{
//                     backgroundColor: '#1c1f24',
//                     backdropFilter: 'blur(10px)',
//                     padding: '20px',
//                     borderRadius: '10px',
//                     maxWidth: '330px',
//                     height: '85vh',
//                     margin: 'auto',
//                     marginTop: '4vh',
//                     overflowY: 'auto',
//                 }}>
//                     <Box>
//                         <Grid container spacing={4} textAlign={'center'}>
//                             <Box sx={{ textAlign: 'center', width: '100%', ml: 2, }}>
//                                 <img src={airdropMain} width={200} height={200} style={{ objectFit: "contain" }} alt='EarnCoin' sx={{
//                                     height: 275,
//                                     width: 275,
//                                     position: 'absolute',
//                                     top: '45%',
//                                     left: '50%',
//                                     transform: 'translate(-50%, -50%)',
//                                     zIndex: 1,
//                                     backgroundImage: ""
//                                 }} />
//                                 <Typography fontFamily={'avenir, sans-serif, serif'} fontWeight={700} color={'white'} fontSize={'2rem'} >Get your airdrop </Typography>

//                                 <Typography fontFamily={'avenir, sans-serif, serif'} fontWeight={700} color={'white'} fontSize={'1rem'} sx={{ mt: 1 }}>Your Points: {points} </Typography>




//                             </Box>
//                         </Grid>
//                     </Box>
//                     {showInput ? ( 
//                     <Box
//                         sx={{
//                             display: 'flex',
//                             flexDirection: 'column',
//                             alignItems: 'center',
//                             justifyContent: 'center',
//                             gap: '20px',
//                             textAlign: "center"
//                         }}
//                     > <Typography fontFamily={'avenir, sans-serif, serif'} fontWeight={700} color={'white'} fontSize={'1rem'} sx={{ mt: 1 }}>Enter you web3 wallet address to participate in the Airdrop </Typography>
//                         <TextField
//                             id="outlined-basic"
//                             label="Enter your web3 wallet Address"
//                             variant="outlined"
//                             fullWidth
//                             InputLabelProps={{
//                                 style: { color: 'white' },
//                             }}
//                             InputProps={{
//                                 style: { color: 'white' },
//                             }}
//                             sx={{
//                                 width: '300px',
//                                 '& .MuiOutlinedInput-root': {
//                                     '& fieldset': {
//                                         borderColor: 'white',
//                                     },
//                                     '&:hover fieldset': {
//                                         borderColor: 'white',
//                                     },
//                                     '&.Mui-focused fieldset': {
//                                         borderColor: 'white',
//                                     },
//                                 },
//                             }}
//                             value={walletAddress}
//                             onChange={(e) => setWalletAddress(e.target.value)}
//                         />
//                         <Button
//                             sx={{
//                                 width: '100px',
//                                 background: "#A6FF00",
//                                 color: "black",
//                                 '&:hover': {
//                                     background: '#A6FF00',
//                                     color: "black"
//                                 },
//                             }}
//                             onClick={submitWalletAddress}
//                         >
//                             Submit
//                         </Button>
//                     </Box>
//                  ) : ( 
//                     <Box
//                         sx={{
//                             display: 'flex',
//                             flexDirection: 'column',
//                             alignItems: 'center',
//                             justifyContent: 'center',
//                             gap: '20px',
//                             color: 'white',
//                         }}
//                     >
//                         {/* {!showClaimButton ? ( */}
//                         {/* <Typography variant="h4">
//                                     {formatTime(timeLeft)}
//                                 </Typography>
//                             ) : ( */}
//                         <Button
//                             sx={{
//                                 width: '200px', background: "#A6FF00", color: "black", marginTop: "10px", '&:hover': {
//                                     background: '#A6FF00',
//                                     color: "black"
//                                 },
//                             }}
//                             onClick={handleWithdraw}
//                         >
//                             Claim Airdrop
//                         </Button>
                
//                     </Box>
//                      )} 
//                     <Box style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer', color: 'white' }}>
//                         <CloseIcon onClick={handleClose} />
//                     </Box>
//                 </AnimatedDiv>
//             </Fade>
//         </Modal>
//     );
// };

// export default AirdropModal;





import React, { useEffect, useState } from 'react';
import { Modal, Fade, Typography, Grid, Box, Button, TextField } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import airdropMain from '../images/airdropMain.png';
import axios from 'axios';
import { message } from 'antd';

const slideIn = keyframes`
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100px);
    opacity: 0;
  }
`;

const AnimatedDiv = styled('div')(({ open }) => ({
    animation: `${open ? slideIn : slideOut} 0.5s`,
}));

const AirdropModal = ({ open, handleClose, userData }) => {
    const [walletAddress, setWalletAddress] = useState('');
    const [showInput, setShowInput] = useState(true);
    const [timeLeft, setTimeLeft] = useState(60);
    const [showClaimButton, setShowClaimButton] = useState(false);
    const [points, setPoints] = useState(0);

    useEffect(() => {
        if (timeLeft === 0) {
            setShowClaimButton(true);
        } else if (timeLeft > 0 && !showInput) {
            const timerId = setInterval(() => {
                setTimeLeft((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(timerId);
        }
    }, [timeLeft, showInput]);

    const handleSubmit = () => {
        setShowInput(false);
        setShowClaimButton(false);
        setTimeLeft(60); // set timer to 1 minute
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    useEffect(() => {
        fetchData();
    }, [userData]);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/${userData.id}`);
            const data = response.data;
            setPoints(data.points);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const submitWalletAddress = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/add-wallet-address`, {
                userId: userData.id,
                userWalletAddress: walletAddress,
            });
            message.success('Wallet address submitted successfully');
            handleSubmit();
        } catch (error) {
            console.error('Error submitting wallet address:', error);
        }
    };

    const handleWithdraw = async () => {
        const url = `${process.env.REACT_APP_API_URL}/api/v1/withdraw`;
        const requestBody = {
            userId: userData.id,
            userAddress: walletAddress,
            points: points,
        };

        try {
            await axios.post(url, requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            // Handle successful response here (e.g., update state, display message)
        } catch (error) {
            console.error('Error during withdraw:', error);
            // Handle error here (e.g., display error message)
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            closeAfterTransition
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <AnimatedDiv open={open} style={{
                    backgroundColor: '#1c1f24',
                    backdropFilter: 'blur(10px)',
                    padding: '20px',
                    borderRadius: '10px',
                    maxWidth: '330px',
                    height: '85vh',
                    margin: 'auto',
                    marginTop: '4vh',
                    overflowY: 'auto',
                }}>
                    <Box>
                        <Grid container spacing={4} textAlign={'center'}>
                            <Box sx={{ textAlign: 'center', width: '100%', ml: 2 }}>
                                <img src={airdropMain} width={200} height={200} style={{ objectFit: "contain" }} alt='EarnCoin' sx={{
                                    height: 275,
                                    width: 275,
                                    position: 'absolute',
                                    top: '45%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    zIndex: 1,
                                    backgroundImage: ""
                                }} />
                                <Typography fontFamily={'avenir, sans-serif, serif'} fontWeight={700} color={'white'} fontSize={'2rem'}>Get your airdrop</Typography>
                                <Typography fontFamily={'avenir, sans-serif, serif'} fontWeight={700} color={'white'} fontSize={'1rem'} sx={{ mt: 1 }}>Your Points: {points}</Typography>
                            </Box>
                        </Grid>
                    </Box>
                    {showInput ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '20px',
                                textAlign: "center"
                            }}
                        >
                            <Typography fontFamily={'avenir, sans-serif, serif'} fontWeight={700} color={'white'} fontSize={'1rem'} sx={{ mt: 1 }}>Enter your web3 wallet address to participate in the Airdrop</Typography>
                            <TextField
                                id="outlined-basic"
                                label="Enter your web3 wallet Address"
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{
                                    style: { color: 'white' },
                                }}
                                InputProps={{
                                    style: { color: 'white' },
                                }}
                                sx={{
                                    width: '300px',
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'white',
                                        },
                                    },
                                }}
                                value={walletAddress}
                                onChange={(e) => setWalletAddress(e.target.value)}
                            />
                            <Button
                                sx={{
                                    width: '100px',
                                    background: "#A6FF00",
                                    color: "black",
                                    '&:hover': {
                                        background: '#A6FF00',
                                        color: "black"
                                    },
                                }}
                                onClick={submitWalletAddress}
                            >
                                Submit
                            </Button>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '20px',
                                color: 'white',
                            }}
                        >
                            {!showClaimButton ? (
                                <Typography fontFamily={'avenir, sans-serif, serif'} fontWeight={700} color={'white'} fontSize={'1rem'}   variant="h4">
                                  Your Airdrop Unlock on :  {formatTime(timeLeft)}
                                </Typography>
                            ) : (
                                <Button
                                    sx={{
                                        width: '200px',
                                        background: "#A6FF00",
                                        color: "black",
                                        marginTop: "10px",
                                        '&:hover': {
                                            background: '#A6FF00',
                                            color: "black"
                                        },
                                    }}
                                    onClick={handleWithdraw}
                                >
                                    Claim Airdrop
                                </Button>
                            )}
                        </Box>
                    )}
                    <Box style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer', color: 'white' }}>
                        <CloseIcon onClick={handleClose} />
                    </Box>
                </AnimatedDiv>
            </Fade>
        </Modal>
    );
};

export default AirdropModal;
