import React, { useState } from 'react';
import { Modal, Backdrop, Fade, Button, Typography, Grid, Paper, Box } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { message } from 'antd';

const slideIn = keyframes`
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100px);
    opacity: 0;
  }
`;

const AnimatedDiv = styled('div')(({ theme, open }) => ({
  animation: `${open ? slideIn : slideOut} 0.5s`,
}));

const FreindsModal = ({ open, handleClose, userData, referralCount, referralList }) => {
   
  const handleReferral = (event) => {
    navigator.clipboard.writeText(event.target.id);
    message.success('Copied to clipboard!');
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      closeAfterTransition
      // BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <AnimatedDiv open={open} style={{
           background: 'linear-gradient(to bottom, #080708, #000, #2f4734)',
          backdropFilter: 'blur(4px)',
          padding: '20px',
          borderRadius: '10px',
          maxWidth: '330px',
          height: '85vh',
          margin: 'auto',
          marginTop: '4vh',
          overflowY: 'auto',
        }}>

<Box
  sx={{
    position: 'relative',
    width: "326px",
    height: "200px",
    borderRadius: "23px",
    overflow: 'hidden',
    background: 'linear-gradient(300.26deg, rgba(55, 114, 255, 0.1) 31.58%, rgba(8, 7, 8, 0.06) 63.56%)',
  }}
>
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      border: '1px solid',
      borderImageSource: 'linear-gradient(270deg, rgba(55, 114, 255, 0.8) 0%, rgba(33, 68, 153, 0.8) 100%)',
      borderImageSlice: 5,
      borderRadius: "23px",
      pointerEvents: 'none',
    }}
  />
  <Box
    sx={{
      position: 'relative',
      width: "100%",
      height: "100%",
      
      borderRadius: "23px",
    }}
  >
    {/* Your content goes here */}
  </Box>
</Box>
          <Grid container spacing={4} textAlign={'center'}>
            <Box sx={{ textAlign: 'center', width: '100%', ml:2 }}>
               <Typography fontFamily={'avenir, sans-serif, serif'} fontWeight={700} color={'white'} fontSize={'1.5rem'} sx={{ mt: 4 }}>You invtited : </Typography>
                <Typography fontFamily={'avenir, sans-serif, serif'} fontWeight={700} color={'white'}  fontSize={'7.5rem'}>
                  {referralCount} 
                  </Typography>
            </Box>
          </Grid>
          <Grid container spacing={4} textAlign={'center'}>
            {/* copy referral link by click */}
            <Box sx={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', marginLeft: '30px' }}>
               <Typography fontFamily={'avenir, sans-serif, serif'} color={'white'}   fontWeight={700} fontSize={'1.5rem'} sx={{ mt: 4 }}>
                   referral link : 
                </Typography>
                <Typography fontFamily={'avenir, sans-serif, serif'} fontWeight={700} fontSize={'1rem'} sx={{ p:2, backgroundColor: '#272a2f', color: 'white', borderRadius: '10px' }}>
                      {`https://t.me/${process.env.REACT_APP_TELEGRAM_BOT_USER_NAME}?start=${userData.id}`}
                 </Typography>
                  <Button variant="contained" size="small" sx={{ borderRadius: '10px', backgroundColor: '#272a2f', color: 'white', mt: 2, 
                  '&:hover': { backgroundColor: 'transparent'}}} id={`https://t.me/${process.env.REACT_APP_TELEGRAM_BOT_USER_NAME}?start=${userData.id}`} onClick={handleReferral}>
                      Copy Link <ContentCopyIcon fontSize="small" sx={{ ml: 1 }} />
                  </Button>
            </Box>
            <Box sx={{alignItems: 'center', width: '100%', ml:2 }}>
               <Typography fontFamily={'avenir, sans-serif, serif'} fontWeight={700} color={'white'}  fontSize={'1.5rem'} sx={{ mt: 4 }}>
                 <img style={{verticalAlign:'middle'}} width="30" height="30" src="https://img.icons8.com/color/48/friends--v1.png" alt="paint-palette"/>
                  Your friends : 
                  </Typography>
                {referralList?.map((referral, index) => (
                <Box key={index} bgcolor={'#3F4369'} style={{ textAlign: 'left', width: '95%', margin:'5px', padding: '10px', borderRadius: '10px' }}>
                  <Typography fontFamily={'avenir, sans-serif, serif'} color={'white'} fontWeight={700} fontSize={'1.5rem'}>{referral.firstname} {referral.lastname}</Typography>
                  <Typography fontFamily={'avenir, sans-serif, serif'} color={'white'}  fontWeight={700} fontSize={'1rem'}>ID : {referral.userId}</Typography>
                </Box>
                ))}
                 {referralList.length === 0 && (
                <Box bgcolor={'#272a2f'} style={{ textAlign: 'left', width: '95%', margin:'5px', padding: '10px', borderRadius: '10px' }}>
                  <Typography fontFamily={'avenir, sans-serif, serif'} fontWeight={700} color={'white'} fontSize={'1.5rem'}>No friends :(</Typography>
                </Box>
                )}
            </Box>
          </Grid>
          <div style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer', color: 'white' }}>
            <CloseIcon onClick={handleClose} />
          </div>
        </AnimatedDiv>
      </Fade>
    </Modal>
  );
};

export default FreindsModal;
