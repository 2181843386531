// slices/userSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';



const userSlice = createSlice({
  name: 'user',
  initialState: {
    userData: {},
    status: 'idle', // 'loading', 'succeeded', 'failed'
    error: null,
    userId: ''
  },

  reducers: {
    setUserData : (state,action)=>{
        state.userData = action.payload;
    },
 setUserId: (state, action) => {
        state.userId = action.payload;

 }
    

  },

});

export const {  setUserData ,setUserId} = userSlice.actions;

export default userSlice.reducer;
