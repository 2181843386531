// BottomNavBar.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import  tap from '../images/EarnHash.svg'
import  mates from '../images/Mates.svg'
import  boosts from '../images/Boost.svg'
import  Stat from '../images/Stat.svg'

const BottomNavBar = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        background: '#F4FDFF0A',
        color: 'white',
        borderRadius: '12px 12px 0 0',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: '10px 0',
        zIndex: 1000,
      }}
    >
        <Button
        variant="contained"
        onClick={() => navigate('/')}
        sx={{
          color: 'white',
          width: '70px',
          height: "76px",
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column-reverse',
          fontSize: "16px",
          lineHeight: "20px",
          borderRadius: '12px',
          background: 'linear-gradient(180deg, rgba(244, 253, 255, 0.04) 0%, rgba(55, 114, 255, 0.32) 100%)',
          border: '1px solid',
          borderColor: 'transparent',
          padding: '10px',
          position: 'relative',
          overflow: 'hidden',
          fontFamily: "Lexend",
          border: '1px solid',
          borderImageSource: 'linear-gradient(180deg, rgba(215, 248, 255, 0.5) 0%, #3772FF 100%)',
          borderImageSlice: 1,
          gap: "5px",
          '&:hover': {
            borderColor: 'transparent',
          },
          '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
            borderRadius: '12px',
          },
          justifyContent: 'center',
          '&:active': {
            backgroundColor: '#66B6FF',
            color: 'black',
          },
        }}
      >
        Tap  <img src={tap} width={40} height={40} />
      </Button>
      <Button
        variant="contained"
        onClick={() => navigate('/mates')}
        sx={{
          color: 'white',
          width: '70px',
          height: "76px",
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column-reverse',
          fontSize: "16px",
          lineHeight: "20px",
          borderRadius: '12px',
          background: 'linear-gradient(180deg, rgba(244, 253, 255, 0.04) 0%, rgba(55, 114, 255, 0.32) 100%)',
          border: '1px solid',
          borderColor: 'transparent',
          padding: '10px',
          position: 'relative',
          overflow: 'hidden',
          fontFamily: "Lexend",
          border: '1px solid',
          borderImageSource: 'linear-gradient(180deg, rgba(215, 248, 255, 0.5) 0%, #3772FF 100%)',
          borderImageSlice: 1,
          borderRadius: '10px', // Set your desired border radius
          padding: '10px', // Optional: Add padding to avoid content overlapping with border
          overflow: 'hidden', // Optional: Hide any overflow to maintain the border radius
          gap: "5px",
          '&:hover': {
            borderColor: 'transparent',
          },
          '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
            borderRadius: '12px',
          },
          justifyContent: 'center',
          '&:active': {
            backgroundColor: '#66B6FF',
            color: 'black',
          },
        }}
      >
        Mates <img src={mates} width={40} height={40}/>
      </Button>
      <Button
        variant="contained"
        onClick={() => navigate('/earn')}
        sx={{
          color: 'white',
          width: 'auto',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column-reverse',
          fontSize: "16px",
          lineHeight: "20px",
          borderRadius: '12px',
          background: 'linear-gradient(180deg, rgba(244, 253, 255, 0.04) 0%, rgba(55, 114, 255, 0.32) 100%)',
          border: '1px solid',
          borderColor: 'transparent',
          padding: '10px',
          position: 'relative',
          overflow: 'hidden',
          fontFamily: "Lexend",
          border: '1px solid',
          borderImageSource: 'linear-gradient(180deg, rgba(215, 248, 255, 0.5) 0%, #3772FF 100%)',
          borderImageSlice: 1,
          // gap: "5px",
          '&:hover': {
            borderColor: 'transparent',
          },
          '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
            borderRadius: '12px',
          },
          justifyContent: 'center',
          '&:active': {
            backgroundColor: '#66B6FF',
            color: 'black',
          },
        }}
      >
        Earn <img src={tap} width={40} height={40}/>
      </Button>
      <Button
        variant="contained"
        onClick={() => navigate('/boost')}
        sx={{
          color: 'white',
          width: '70px',
          height: "76px",
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column-reverse',
          fontSize: "16px",
          lineHeight: "20px",
          borderRadius: '12px',
          background: 'linear-gradient(180deg, rgba(244, 253, 255, 0.04) 0%, rgba(55, 114, 255, 0.32) 100%)',
          border: '1px solid',
          borderColor: 'transparent',
          border: '1px solid',
          borderImageSource: 'linear-gradient(180deg, rgba(215, 248, 255, 0.5) 0%, #3772FF 100%)',
          borderImageSlice: 1,
          padding: '10px',
          position: 'relative',
          overflow: 'hidden',
          fontFamily: "Lexend",
          gap: "5px",
          '&:hover': {
            borderColor: 'transparent',
          },
          '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
            borderRadius: '12px',
          },
          justifyContent: 'center',
          '&:active': {
            backgroundColor: '#66B6FF',
            color: 'black',
          },
        }}
      >
        Boosts <img src={boosts} width={40} height={40}/>
      </Button>
      <Button
        variant="contained"
        onClick={() => navigate('/stats')}
        sx={{
          color: 'white',
          width: '70px',
          height: "76px",
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column-reverse',
          fontSize: "16px",
          lineHeight: "20px",
          borderRadius: '12px',
          background: 'linear-gradient(180deg, rgba(244, 253, 255, 0.04) 0%, rgba(55, 114, 255, 0.32) 100%)',
          border: '1px solid',
          borderColor: 'transparent',
          padding: '10px',
          position: 'relative',
          overflow: 'hidden',
          fontFamily: "Lexend",
          border: '1px solid',
          borderImageSource: 'linear-gradient(180deg, rgba(215, 248, 255, 0.5) 0%, #3772FF 100%)',
          borderImageSlice: 1,

          gap: "5px",
          '&:hover': {
            borderColor: 'transparent',
          },
          '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
            borderRadius: '12px',
          },
          justifyContent: 'center',
          '&:active': {
            backgroundColor: '#66B6FF',
            color: 'black',
          },
        }}
      >
        Stats <img src={Stat} width={40} height={40}/>
      </Button>
    </Box>
  );
};

export default BottomNavBar;