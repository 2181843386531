import { Box, colors } from '@mui/material'
import { Button, Typography } from 'antd'
import React from 'react'
import GiftIMG from '../images/Gift.svg'
import tap from '../images/EarnHash.svg'
import coin from '../images/Tap.svg'
import { styled } from '@mui/system';
import CopyToClipboard from '../images/CopyToClipboard.svg'
import Profile from '../images/Profile.svg'
import brass3 from '../images/brass3.svg'
import PremiumIMG from '../images/PremiumLogo.svg'

const Mates = () => {

  const GradientText = styled(Typography)({
    background: 'linear-gradient(400.600deg, #3772FF 31.58%, #F4FDFF 63.56%)',
    padding: '0.2em 0.5em', // Adjust padding as needed
    borderRadius: '5px', // Optional: add border-radius for rounded corners
    display: 'inline-block', // Ensures the background does not span the whole width
    width: "244px",
    height: "49px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "15px"


  });

  return (
    <div style={{
      height: "100vh", display: "flex",
      justifyContent: "center",
      alignItems: "start",
      mt: "20px",
      background: "#080708"

    }}>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "column",
          border: "1px solid #3772FF",
          width: '326px',
          height: '370px',
          borderRadius: "23px",
          mt: "20px",
        }}
      >
        <Box sx={{ mt: "31px", textAlign: 'center' }}>
          <Typography style={{ color: '#F4FDFF', fontSize: "15px", fontWeight: 500 }}>
            Your Rewards
          </Typography>
          <Typography style={{ color: '#3772FF', textAlign: 'center', fontSize: "20px", fontWeight: 700 }}>
            2000
          </Typography>
          <Typography>
            <img src={GiftIMG} width={100} height={100} alt="Gift" />
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'left' }}>
          <Typography style={{ color: '#F4FDFF', fontSize: "15px", fontWeight: 500 }}>
            Invite mates to get bonuses
          </Typography>
          <Box
            bgcolor={'#080F12'}
            color={'white'}
            style={{ textAlign: 'left', width: '95%',  padding: '10px', borderRadius: '10px', border: "1px solid #3A4A70" }}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb:1
            }}
          >
            <img
              src={tap}
              width={20} height={20} style={{ marginRight: '10px' }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', flexGrow: 1, }}>
              <Typography style={{ color: '#F4FDFF', fontSize: "14px", fontWeight: 500 }}>
                Invite mates
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: "3px" }}>
                <img
                  src={coin}
                  width={15} height={15}
                />
                <Typography style={{ color: "#F1E38A", lineHeight: "17.5px", fontWeight: 700 }}>
                  120000
                </Typography>

              </Box>

            </Box>

          </Box>
          <Box
            bgcolor={'#080F12'}
            color={'white'}
            style={{ textAlign: 'left', width: '95%', padding: '10px', borderRadius: '10px', border: "1px solid #3A4A70" }}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <img
              src={PremiumIMG}
              width={20} height={20} style={{ marginRight: '10px' }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', flexGrow: 1, }}>
              <Typography style={{ color: '#F4FDFF', fontSize: "14px", fontWeight: 500 }}>
              Mates with Telegram Premium
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: "3px" }}>
                <img
                  src={coin}
                  width={15} height={15}
                />
                <Typography style={{ color: "#F1E38A", lineHeight: "17.5px", fontWeight: 700 }}>
                  120000
                </Typography>

              </Box>

            </Box>

          </Box>
        </Box>
        <Box sx={{ mt: 5, display: "flex", justifyContent: "center", alignItems: "center", gap: "20px" }}>
          <GradientText style={{ fontSize: "180x", fontWeight: 500, lineHeight: "22.5px" }}>
            Invite mates
          </GradientText>
          <GradientText style={{ width: "61px", height: "49px" }}>
            <img src={CopyToClipboard} alt="Copy to Clipboard" />
          </GradientText>
        </Box>
        <Box
      bgcolor={'#080F12'}
      color={'white'}
      style={{
        textAlign: 'left', width: '95%', margin: '8px', padding: '10px', borderRadius: '10px',
        // border: "1px solid #3A4A70"
      }}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <img
        src={Profile} // Assuming Profile is imported or defined elsewhere
        width={50} height={50} style={{ marginRight: '10px' }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', flexGrow: 1 }}>
        <Typography style={{ color: '#F4FDFF', fontSize: "14px", fontWeight: 500 }}>
          Hashtagdrummer
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: "3px" }}>
          <img
            src={brass3} // Assuming brass3 is imported or defined elsewhere
            width={20} height={20}
          />
          <Typography style={{ color: "#F1E38A", lineHeight: "17.5px", fontWeight: 700 }}>
          Drummer
          </Typography>
        </Box>
      </Box>
      {/* New content at the end */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={coin} // Replace with the path or URL of your image
          alt="Additional Image"
          width={15} height={15} style={{ marginRight: '5px' }}
        />
        <Typography style={{ color: '#F4FDFF', fontSize: '14px' }}>
          56.4M
        </Typography>
      </Box>
    </Box>
        <Box
      bgcolor={'#080F12'}
      color={'white'}
      style={{
        textAlign: 'left', width: '95%', margin: '8px', padding: '10px', borderRadius: '10px',
        // border: "1px solid #3A4A70"
      }}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <img
        src={Profile} // Assuming Profile is imported or defined elsewhere
        width={50} height={50} style={{ marginRight: '10px' }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', flexGrow: 1 }}>
        <Typography style={{ color: '#F4FDFF', fontSize: "14px", fontWeight: 500 }}>
          Hashtagdrummer
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: "3px" }}>
          <img
            src={brass3} // Assuming brass3 is imported or defined elsewhere
            width={20} height={20}
          />
          <Typography style={{ color: "#F1E38A", lineHeight: "17.5px", fontWeight: 700 }}>
            Drummer
          </Typography>
        </Box>
      </Box>
      {/* New content at the end */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={coin} // Replace with the path or URL of your image
          alt="Additional Image"
          width={15} height={15} style={{ marginRight: '5px' }}
        />
        <Typography style={{ color: '#F4FDFF', fontSize: '14px' }}>
          156.2M
        </Typography>
      </Box>
    </Box>
      </Box>
    </div>
  )
}

export default Mates
