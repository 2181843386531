import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
// import { setUserData } from '../store/slices/userSlice';

import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  Avatar,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system";
import { message } from "antd";
import {
  CssBaseline,
  ThemeProvider,
  createTheme,
  LinearProgress,
} from "@mui/material";
import SkinsModal from "./Skins";
import FriendsModal from "./Freinds";
import LeaderboardModal from "./Leaderboard";
import { keyframes } from "@emotion/react";
import MyProgress from "./Progress";
import axios from "axios";
import leafRight from "../images/leaf-right.png";
import coin from "../images/Coin.svg";
import Mates from "../images/Mates.svg";
import Earnhash from "../images/EarnHash.svg";
import Boost from "../images/Boost.svg";
import Stat from "../images/Stat.svg";
import flash from "../images/flash.svg";

// import images of skins
import Drumstick1 from "../images/drumstick1.svg";
import Drumstick2 from "../images/drumstick2.svg";
import OrangeCoin from "../images/Drum.svg";
import defaultCoin from "../images/basicChar.png";
import GuardCoin from "../images/goldchar.png";
import BattleCoin from "../images/Diamand.png";
import TaskModal from "./Earn";
import AirdropModal from "./Airdrop";
import flyingCoin from "../images/flyingCoin.svg";
import BoostModal from "./Boost";
import brass3 from "../images/brass3.svg";
import { useDispatch } from "react-redux";
import { init, setData } from "../apis";
import { setUserId } from "../store/slices/userSlice";
import Level2Image from '../images/Level2.svg'
import Level3Image from '../images/Level3.svg'
import Level4Image from '../images/Level4.svg'
import Level5Image from '../images/Level5.svg'

const isDesktop = window.innerWidth > 1000;
const theme = createTheme();

// Styled components for the gold buttons
const GoldButton = styled(Button)({
  backgroundColor: "transparent",
  borderRadius: 15,
  width: "20vw",
  margin: "10px",
  padding: window.innerHeight < 740 ? "5px" : "10px",
  fontFamily: "avenir",
  fontSize: "19px",
  textTransform: "Capitalize",
  fontWeight: 800,
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "transparent",
    boxShadow: "none",
    color: "white",
  },
});

const CoinLogo = styled(Box)({
  width: "35vw",
  marginBottom: "15px",
  // filter: 'hue-rotate(12deg) drop-shadow(0px 0px 25px #0152AC)',
  [theme.breakpoints.down("md")]: {
    width: window.innerHeight < 740 ? "67vw" : "75vw",
    marginBottom: window.innerHeight < 740 ? "10px" : "35px",
  },
});

// keyframes for animation
const expand = keyframes`
   from, to { width: ${isDesktop ? "33vw" : "73vw"}; }
   20% { width: ${isDesktop ? "28.5vw" : "68vw"}; }
   50% { width: ${isDesktop ? "30vw" : "70vw"}; }
`;

const fontSizeAnim = keyframes`
   from, to { font-size: ${isDesktop ? "22px" : "26px"}; }
   50% { font-size: ${isDesktop ? "22px" : "26px"}; }
`;

const floatUpAndFadeOut = keyframes`
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(-100px);
    opacity: 0;
  }
`;

const drumstickAnimation1 = keyframes`
  0% { transform: translateX(0) translateY(0) rotate(0deg); }
  50% { transform: translateX(0px) translateY(0px) rotate(20deg); }
  100% { transform: translateX(0px) translateY(0px) rotate(40deg); }
`;

const drumstickAnimation2 = keyframes`
  0% { transform: translateX(0) translateY(0) rotate(0deg); }
  50% { transform: translateX(0px) translateY(0px) rotate(-20deg); }
  100% { transform: translateX(0px) translateY(0px) rotate(-40deg); }
`;

export default function CoinApp(props) {
  // const {  } = props;
  // const [userData, setUserData] = useState();
  const [userPoints, setUserPoints] = useState();
  const [expandAnimation, setExpandAnimation] = useState("");
  const [fontSizeAnimation, setFontSizeAnimation] = useState("");
  const [userCurrentSkinID, setUserCurrentSkinID] = useState();
  const [userInfo, setUserInfo] = useState(null);
  const [pointCount, setPointCount] = useState(0);
  const [userMiningInfo, setUserMiningInfo] = useState({
    status: "idle",
    perClick: 1,
    pointCount: 0,
    limit: 0,
    max: 0,
  });
  const [textPoints, setTextPoints] = useState([]);
  const [value, setValue] = useState(0);

  const [audio, setAudio] = useState(null);


  const getImageForGrade = (grade) => {
    switch (grade) {
      case 'Drummer':
        return OrangeCoin;
      case 'Guitarist':
        return Level2Image;
      case 'Saxophonist':
        return Level3Image;
      case 'Keyboardist':
        return Level4Image;
      case 'Singer':
        return Level5Image;
      default:
        return OrangeCoin; // Default image if grade is not recognized
    }
  };

  const handleMiningInfo = () => {
    if (typeof userData.id === "undefined") return null;
    // get user data by api and change limit
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/user/${userData.id}`)
      .then((response) => {
        if (response.data && "points" in response.data) {
          setPointCount(response.data.points);
        }
        if (response.data && "limit" in response.data) {
          userMiningInfo((prevMiningInfo) => {
            return { ...prevMiningInfo, limit: response.data.limit };
          });
        }
      })
      .catch((error) => console.error("Mining error:", error));
  };
  useEffect(() => {
    const audioElement = new Audio(
      `${process.env.PUBLIC_URL}/assets/tribal-dry-drum-558.wav`
    );
    setAudio(audioElement);
  }, []);

  const [animateDrumsticks, setAnimateDrumsticks] = useState(false);

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const userId = useSelector((state) => state.user.userId);
  // const userStatus = useSelector((state) => state.user.userData);

  const userCurrentSkinImage = getImageForGrade(userData.grade);

useEffect(()=> {
  dispatch(init())
},[])

  useEffect(() => {
    dispatch(setData(userId));
  }, [userId]);

  const getStyle = () => {
    const baseStyle = {
      position: "absolute",
      top: "75%",
      color: "aliceblue",
      animation: fontSizeAnimation,
      fontFamily: "avenir",
      fontSize: isDesktop ? "18px" : "13px",
      // width: "92%", // Default width value
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection:"column",
      gap:"5px",

      "@media (min-width: 769px)": {
        left: "25vw", // Default left value for larger screens
      },

      // Media query for screens 768px or less
      "@media (max-width: 768px)": {
        width: "92vw", // Adjust width for smaller screens
        // Additional styles specific to smaller screens
      },
    };

    return baseStyle;
  };

  const getPointLeftPosition = (pointCount) => {
    const thresholds = [
      [999999999999999, "17vw"],
      [9999999999999, "21vw"],
      [999999999999, "23vw"],
      [9999999999, "27vw"],
      [999999999, "29vw"],
      [99999999, "32vw"],
      [9999999, "34vw"],
      [999999, "36vw"],
      [99999, "38vw"],
      [9999, "40vw"],
      [999, "42vw"],
      [99, "45vw"],
      [9, "46vw"],
    ];

    for (const [threshold, position] of thresholds) {
      if (pointCount > threshold) {
        return position;
      }
    }

    return "47.5vw";
  };

  const removePoint = (id) => {
    setTextPoints(textPoints.filter((point) => point.id !== id));
  };

  const getCoinSkinShadow = (userCurrentSkinID) => {
    switch (userCurrentSkinID) {
      case 1:
        return "0px 0px 45px #A6FF00";
      case 2:
        return "0px 0px 45px #FAE088";
      case 3:
        return "0px 0px 45px #5c716c";
      case 4:
        return "0px 0px 45px skyblue";
      default:
        return "0px 0px 45px #0152AC";
    }
  };

  const handleGetTapUserData = useCallback(async () => {
    try {
      if (userId) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/user/${userId}/getUserTapData`
        );
        if (response.data && response.data.data) {
          console.log("User tap data ===>", response);
          const data = response.data.data;
          setUserInfo(data); // Update state with fetched data

          const boosts =
            data.boosts && data.boosts[0]
              ? data.boosts[0]
              : { denominator: 0, numerator: 0 };

          setUserMiningInfo({
            status: "Active",
            pointCount: data.user && data.user.points ? data.user.points : 0,
            perClick: data.tapPoints || 0,
            limit: boosts.denominator,
            max: boosts.numerator,
          });
        } else {
          console.error("Invalid response structure:", response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching getUserTapData:", error);
    }
  }, [userId]);

  useEffect(() => {
    handleGetTapUserData(); // Call the function to fetch data when the component mounts
  }, [handleGetTapUserData]);

  useEffect(() => {
    // handleGetTapUserData();

    const interval = setInterval(async () => {
      setUserMiningInfo((prevMiningInfo) => {
        // Only increase limit if it's below the max
        if (userData.id)
          axios.get(`${process.env.REACT_APP_API_URL}/api/user/${userData.id}`);
        if (prevMiningInfo.limit < prevMiningInfo.max) {
          return { ...prevMiningInfo, limit: prevMiningInfo.limit + 1 };
        } else {
          // Otherwise, keep the previous state unchanged
          clearInterval(interval); // If limit reached max, clear the interval to stop it
          return prevMiningInfo;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [userMiningInfo.limit]);

  useEffect(() => {
    const req = async () => {
      try {
        await handleGetTapUserData(); // Fetch data again after tapping
      } catch (error) {
        console.error("Error updating score:", error);
      }
    };

    req(); // Call the async function

  }, [userId]); // Dependency array

  const handleCoinClick = async (event) => {
    if (userMiningInfo.limit !== 0) {
      const newPointCount = userMiningInfo.pointCount + userMiningInfo.perClick;
      const newLimit = userMiningInfo.limit - userMiningInfo.perClick < 0 ? 0 : userMiningInfo.limit - userMiningInfo.perClick;
      setUserMiningInfo({
        pointCount: newPointCount,
        limit: newLimit,
        status: "mining",
        perClick: userMiningInfo.perClick, // Using userMiningInfo here to ensure data consistency
        max: userMiningInfo.max,
      });
  
      setExpandAnimation(`${expand} 0.1s ease`);
      setFontSizeAnimation(`${fontSizeAnim} 0.1s ease`);
      audio.play();
  
      const x = event.clientX;
      const y = event.clientY;
      setTextPoints([...textPoints, { x, y, id: Date.now() }]);
      setTimeout(() => {
        setExpandAnimation("");
        setFontSizeAnimation("");
      }, 200);
  
      // Call the tap API
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/user/tap`, { userId });
        console.log("response tap", response);
        // handleGetTapUserData(); // Fetch data again after tapping
        setUserMiningInfo({
          pointCount: response.data.data.user.points,
          // limit: newLimit,
          status: "mining",
          perClick:response.data.data.tapPoints, // Using userMiningInfo here to ensure data consistency
          max: response.data.data.boosts[0].denominator,
        });
      } catch (error) {
        console.error("Error updating score:", error);
        if (error.response && error.response.status === 404) {
          console.error("Endpoint not found (404)");
        }
      }
    } else {
      setUserMiningInfo({ ...userMiningInfo, status: "stop" });
      if (window.Telegram.WebApp) {
        window.Telegram.WebApp.showAlert("Mining limit reached. Please try again later.");
      }
    }
  };
  



 console.log("userData", userData);  
  




  return (
    <>
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          p: 1,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "125px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "326px",
            height: "114px",
            display: "flex",
            // alignItems: 'center',
            // justifyContent: 'center',
            flexDirection: "column",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: "20px",
              padding: "2px", // adjust as needed
              // background: 'linear-gradient(270deg, rgba(55, 114, 255, 0.8) 0%, rgba(33, 68, 153, 0.8) 100%)',
              // WebkitMask:
              //   'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
              WebkitMaskComposite: "xor",
              maskComposite: 'exclude',
            border:"1px solid #3772FF"
            },
            borderRadius: "20px",
            padding: "10px",
            background: "rgba(0, 0, 0, 0.1)", // adjust background color as needed
            zIndex: 1,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              color: "white",
              fontFamily: "Lexend-thin",
              fontSize: "18px",
              fontWeight: 700,
              marginTop: "19px",
              color: "#F4FDFFDF",
            }}
          >
            Your Hashtag
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <Typography>
              <img src={coin} width={40} height={40} />
            </Typography>
            <Typography
              sx={{
                color: "white",
                fontSize: "32px",
                fontWeight: 700,
                lineHeight: "39.01px",
              }}
            >
              {/* {userData.userPoints} */}
              {userMiningInfo.pointCount}
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "145px",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "135px",
              height: "42px",
              display: "flex",
              // alignItems: 'center',
              // justifyContent: 'center',
              borderRadius: "12px",
              border: "1px solid #D7F8FF4D",
              flexDirection: "column",
              color: "#F4FDFFDF",
              background: "#203E84",
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                // borderRadius: '12px',
                padding: "2px", // adjust as needed
                // background: 'linear-gradient(270deg, rgba(55, 114, 255, 0.8) 0%, rgba(33, 68, 153, 0.8) 100%)',
                // WebkitMask:
                //   'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                WebkitMaskComposite: "xor",
                maskComposite: "exclude",
              },
              borderRadius: "12px",
              // padding: '10px',
              // background: 'rgba(0, 0, 0, 0.1)', // adjust background color as needed
              zIndex: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "3px",
              }}
            >
              <Typography>
                <img src={brass3} width={29} height={29} />
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  fontWeight: 400,
                  font: "16px",
                  lineHeight: "20px",
                }}
              >
                {userData.grade}
              </Typography>
            </Box>
          </Box>
        </Box>
        <div style={{ position: "relative", top: "25%" }}>
          <Box
            sx={{
              position: "relative",
              width: 300,
              height: 500,
            }}
            onClick={handleCoinClick}
            // onClick={handleClick}
          >
            {/* Main Coin Image */}
            <Box
              component="img"
              src={userCurrentSkinImage}
              alt="Coin Logo"
              sx={{
                width: "100%",
                height: "100%",
                animation: expandAnimation,
                filter: `drop-shadow(${getCoinSkinShadow(userCurrentSkinID)})`,
                "&:hover": { cursor: "pointer" },
              }}
            />

            {/* {textPoints.map((point) => (
        <Box
          key={point.id}
          sx={{
            position: "absolute",
            left: point.x - 10,
            top: point.y - 20,
            animation: `${floatUpAndFadeOut} 1s ease forwards`, // forwards keeps the end state after animation completes
            fontSize: `${isDesktop ? "40px" : "35px"}`,
            fontFamily: "avenir",
            color: "white",
          }}
          onAnimationEnd={() => removePoint(point.id)} // remove element after animation
        >
          +{userMiningInfo.perClick}
        </Box>
      ))} */}
            {/* Flying Coin Image - Centered Top */}
            <Box
              component="img"
              src={flyingCoin}
              alt="flying coin"
              sx={{
                position: "absolute",
                top: "-12%",
                left: "50%",
                transform: "translateX(-50%)", // Center horizontally
                width: "200px",
                height: "400px",
                // animation: animateDrumsticks ? `${drumstickAnimation1} 1s ease` : 'none',
              }}
            />

            {/* Drumstick 1 */}
            <Box
              component="img"
              src={Drumstick1}
              alt="Drumstick 1"
              sx={{
                position: "absolute",
                bottom: "5%",
                right: "1%",
                width: "100px",
                height: "300px",
                animation: animateDrumsticks
                  ? `${drumstickAnimation2} 4s ease`
                  : "none",
              }}
            />

            {/* Drumstick 2 */}
            <Box
              component="img"
              src={Drumstick2}
              alt="Drumstick 2"
              sx={{
                position: "absolute",
                bottom: "5%",
                left: "1%",
                width: "100px",
                height: "300px",
                animation: animateDrumsticks
                  ? `${drumstickAnimation1} 1s ease`
                  : "none",
              }}
            />
          </Box>
        </div>
        <p style={getStyle()} className="mining-info-container">
        <Box
         sx={{
          display:"flex",
          alignItems:"center",
          justifyContent:"center",

         }}
         >
         <img
          style={{ verticalAlign: "bottom", marginRight: "5px" }}
          width="15"
          height="22"
          src={flash}
          alt="flash-on"
        />
        <span style={{ fontSize: `${isDesktop ? "25px" : "20px"}`, marginRight: "auto" }}>
          {userMiningInfo.limit} / {userMiningInfo.max}
        </span>
         </Box>
        <MyProgress value={70} max={100}/>
        </p>
      </Box>
    </>
  );
}
