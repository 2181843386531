import React, { useEffect, useState, useCallback } from 'react';
import { Modal, Backdrop, Fade, Typography, Grid, Paper, Box, Button } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import StackofCoins from '../images/StackofCoins.svg'
import Swipe from '../images/Swipe.svg'
import coin from '../images/EarnHash.svg'
import Tap from '../images/Tap.svg'
import GoArrow from '../images/GoArrow.svg'
import Telegram from '../images/Telegram.svg'
import X from '../images/x-twitter.svg'
import Youtube from '../images/YouTube.svg'
import tiktok from '../images/Tiktok.svg'
import Dailyrewards  from '../images/Dailyrewards.svg'

const slideIn = keyframes`
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100px);
    opacity: 0;
  }
`;

const AnimatedDiv = styled('div')(({ open }) => ({
  animation: `${open ? slideIn : slideOut} 0.5s`,
}));

const Earn = ({ open, handleClose, userData, task }) => {
  const [tasks, setTasks] = useState([]);
  const [claimButtonVisible, setClaimButtonVisible] = useState(null);
  const [claimedIndex, setClaimedIndex] = useState(null);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [isUrlOpened, setIsUrlOpened] = useState([]);
  const [showLoader, setShowLoader] = useState({});
  const [id, setId] = useState([]);

  // const fetchData = useCallback(async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_URL}/api/v1/tasks/${userData.id}`,
  //     );
  //     const data = response.data;
  //     setTasks(data.tasks);
  //     if (data.tasks && data.tasks.length > 0) {
  //       const taskIds = data.tasks.map((task) => task._id);
  //       setId(taskIds);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // }, [userData.id]);


  // const completeTask = async (id) => {
  //   const data = {
  //     taskId: id,
  //   };

  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_URL}/api/v1/complete-task/${userData.id}`,
  //       data,
  //     );

  //     if (response.status === 200 || response.status === 201) {
  //       setShowLoader(false);
  //       setCompletedTasks((prevCompletedTasks) => [...prevCompletedTasks, id]);
  //       message.success("Task completed successfully");
  //     }
  //   } catch (error) {
  //     console.error("Error completing the task:", error);
  //     setShowLoader(false);
  //   }
  // };

  // const handleClaimButtonClick = (index, task) => {
  //   if (!isUrlOpened[index]) {
  //     if (task.socialUrl) {
  //       window.open(task.socialUrl, '_blank');
  //     }
  //     const updatedIsUrlOpened = [...isUrlOpened];
  //     updatedIsUrlOpened[index] = true;
  //     setIsUrlOpened(updatedIsUrlOpened);
  //   } else {
  //     setShowLoader((prevState) => ({ ...prevState, [task._id]: true }));
  //     completeTask(task._id);
  //     const updatedIsUrlOpened = [...isUrlOpened];
  //     updatedIsUrlOpened[index] = false;
  //     setIsUrlOpened(updatedIsUrlOpened);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, [completeTask, fetchData]);

  return (
    // <Modal
    //   open={open}
    //   onClose={handleClose}
    //   aria-labelledby="modal-title"
    //   aria-describedby="modal-description"
    //   closeAfterTransition
    // //   BackdropComponent={Backdrop}
    //   BackdropProps={{
    //     timeout: 500,
    //   }}
    // >
    // <Fade in={open}>
    //   <AnimatedDiv open={open} style={{
    //     backgroundColor: '#1c1f24',
    //     backdropFilter: 'blur(4px)',
    //     padding: '20px',
    //     borderRadius: '10px',
    //     maxWidth: '330px',
    //     height: '85vh',
    //     margin: 'auto',
    //     marginTop: '4vh',
    //     overflowY: 'auto',
    //     zIndex: 'auto'
    //   }}>
    
    <div style={{ height: '100vh', background: '#080F12',padding: "17px",  }}>
    <Box
    sx={{
      height:"80vh",
      overflowY:"scroll",
      overflowX:"hidden",
      width:"100%",
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      padding:"5px"
   
    }}
    >
    <Box textAlign="center" display="flex" justifyContent="center" alignItems="center" width={'100%'} flexDirection={'column'} mt={25} >
      <Box sx={{ textAlign: 'center', width: '100%',}}>
        <img src={StackofCoins} width={100} height={100} alt="EarnCoin" style={{ filter: 'drop-shadow(2.5px 2.5px 5px gold)' }} />
        <Typography fontFamily="Lexend" fontWeight={700} color="white" fontSize="2rem">Earn Extra Rewards</Typography>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Typography sx={{ color: '#F4FDFF' , fontFamily: "Lexend"}}>
          Invite mates
        </Typography>
        <Typography sx={{ display: 'flex', alignItems: 'center', color: '#F4FDFF', fontFamily: "Lexend" }}>
          Swipe <img src={Swipe} alt="Swipe" style={{ marginLeft: 8 }} />
        </Typography>
      </Box>

      <Box
        bgcolor="#080F12"
        color="white"
        sx={{ textAlign: 'left', width: '95%', margin: '8px', padding: '10px', borderRadius: '10px', border: '1px solid #3A4A70', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <img src={coin} width={20} height={20} style={{ marginRight: '10px', border: '1px solid #3A4A70', padding: '5px', borderRadius: '10px' }} />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', flexGrow: 1 }}>
          <Typography style={{ color: '#F4FDFF', fontSize: '14px', fontWeight: 500, lineHeight: '17.5px', fontFamily: "Lexend" }}>
            Invite 10 mates to get
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <img src={Tap} width={15} height={15} />
            <Typography style={{ color: '#F1E38A', lineHeight: '17.5px', fontWeight: 700, fontFamily: "Lexend" }}>
              500000
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={GoArrow} alt="Additional Image" width={15} height={15} style={{ marginRight: '5px' }} />
        </Box>
      </Box>

      <Box textAlign={'left'}>
        <Typography color="white" fontFamily="Lexend">
          Daily rewards
        </Typography>
      </Box>

      <Box
        bgcolor="#080F12"
        color="white"
        sx={{ textAlign: 'left', width: '95%', margin: '8px', padding: '10px', borderRadius: '10px', border: '1px solid #3A4A70', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <img src={Dailyrewards} width={20} height={20} style={{ marginRight: '10px', border: '1px solid #3A4A70', padding: '5px', borderRadius: '10px' }} />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', flexGrow: 1 }}>
          <Typography style={{ color: '#F4FDFF', fontSize: '14px', fontWeight: 500, lineHeight: '17.5px', fontFamily: "Lexend" }}>
            Daily Vocal Warmup
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <img src={Tap} width={15} height={15} />
            <Typography style={{ color: '#F1E38A', lineHeight: '17.5px', fontWeight: 300, textAlign: "left", fontFamily: "Lexend" }}>
              Earn daily points
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={GoArrow} alt="Additional Image" width={15} height={15} style={{ marginRight: '5px' }} />
        </Box>
      </Box>

      <Box>
        <Typography color="white" fontFamily="Lexend">
          Invite Bonus
        </Typography>
      </Box>

      <Box
        bgcolor="#080F12"
        color="white"
        sx={{ textAlign: 'left', width: '95%', margin: '8px', padding: '10px', borderRadius: '10px', border: '1px solid #3A4A70', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <img src={Telegram} width={20} height={20} style={{ marginRight: '10px', border: '1px solid #3A4A70', padding: '5px', borderRadius: '10px' }} />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', flexGrow: 1 }}>
          <Typography style={{ color: '#F4FDFF', fontSize: '14px', fontWeight: 500, lineHeight: '17.5px', fontFamily: "Lexend" }}>
            Join Telegram Channel
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <img src={Tap} width={15} height={15} />
            <Typography style={{ color: '#F1E38A', lineHeight: '17.5px', fontWeight: 300, fontFamily: "Lexend" }}>
              5000000
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={GoArrow} alt="Additional Image" width={15} height={15} style={{ marginRight: '5px' }} />
        </Box>
      </Box>

      <Box
        bgcolor="#080F12"
        color="white"
        sx={{ textAlign: 'left', width: '95%', margin: '8px', padding: '10px', borderRadius: '10px', border: '1px solid #3A4A70', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <img src={Youtube} width={20} height={20} style={{ marginRight: '10px', border: '1px solid #3A4A70', padding: '5px', borderRadius: '10px' }} />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', flexGrow: 1 }}>
          <Typography style={{ color: '#F4FDFF', fontSize: '14px', fontWeight: 500, lineHeight: '17.5px', fontFamily: "Lexend" }}>
            Follow Youtube
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <img src={Tap} width={15} height={15} />
            <Typography style={{ color: '#F1E38A', lineHeight: '17.5px', fontWeight: 300, fontFamily: "Lexend" }}>
              5000000
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={GoArrow} alt="Additional Image" width={15} height={15} style={{ marginRight: '5px' }} />
        </Box>
      </Box>

      <Box
        bgcolor="#080F12"
        color="white"
        sx={{ textAlign: 'left', width: '95%', margin: '8px', padding: '10px', borderRadius: '10px', border: '1px solid #3A4A70', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <img src={X} width={20} height={20} style={{ marginRight: '10px', border: '1px solid #3A4A70', padding: '5px', borderRadius: '10px' }} />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', flexGrow: 1 }}>
          <Typography style={{ color: '#F4FDFF', fontSize: '14px', fontWeight: 500, lineHeight: '17.5px', fontFamily : "Lexend" }}>
            Follow X (Twitter)
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <img src={Tap} width={15} height={15} />
            <Typography style={{ color: '#F1E38A', lineHeight: '17.5px', fontWeight: 300, fontFamily: "Lexend" }}>
              5000000
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={GoArrow} alt="Additional Image" width={15} height={15} style={{ marginRight: '5px' }} />
        </Box>
      </Box>

      <Box
        bgcolor="#080F12"
        color="white"
        sx={{ textAlign: 'left', width: '95%', margin: '8px', padding: '10px', borderRadius: '10px', border: '1px solid #3A4A70', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <img src={tiktok} width={20} height={20} style={{ marginRight: '10px', border: '1px solid #3A4A70', padding: '5px', borderRadius: '10px' }} />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', flexGrow: 1 }}>
          <Typography style={{ color: '#F4FDFF', fontSize: '14px', fontWeight: 500, lineHeight: '17.5px', fontFamily: "Lexend" }}>
            Follow Tiktok
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <img src={Tap} width={15} height={15} />
            <Typography style={{ color: '#F1E38A', lineHeight: '17.5px', fontWeight: 300, fontFamily:"Lexend" }}>
              5000000
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={GoArrow} alt="Additional Image" width={15} height={15} style={{ marginRight: '5px' }} />
        </Box>
      </Box>
    </Box>
    </Box>
  </div>

  );
};

export default Earn;
