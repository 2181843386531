import { Typography, Box, useMediaQuery, useTheme } from "@mui/material";
import Coin from "../images/Coin.svg";
import React from "react";
import Battery from "../images/Battery.svg";
import Tabla from "../images/Tabla.svg";
import Arrow from "../images/Arrow.svg";
import CarCharger from "../images/CarCharger.svg";
import LongDrum from "../images/LongDrum.svg";
import PeakBattery from "../images/PeakBattery.svg";
import BassDrum from "../images/BassDrum.svg";
const Boost = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width:370px)");
  return (
    <div
      style={{
        height: "100vh",
        overflow: "hidden",
      marginTop: "63px", 
        "@media (max-height:600px)": {
          marginTop: "10vh",
        },
        "@media (max-height:800px)": {
          marginTop: "8vh", 
        },
        "@media (max-height:1000px)": {
          marginTop: "6vh", 
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          flexDirection: "column",

          gap: "20px",
          minWidth: "326px",
          width: isSmallScreen ? "100%" : "",
          marginX: "auto",
          paddingX: !isSmallScreen ? "23px" :"12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            border: "1px solid #3772FF",

            width: "100%",
            flexDirection: "column",
            borderRadius: "23px",
            paddingY: "15px",
            backgroundImage: "linear-gradient(#3772FF)",
          }}
        >
          <Typography
            component="p"
            variant="body1"
            sx={{
              fontSize: "16px",
              lineHeight: "20px",
              fontWeight: 400,
              color: "#F4FDFF",
              fontFamily: "Lexend",
            }}
          >
            Your Hashtag
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "7px",
            }}
          >
            <img src={Coin} alt="coin.svg" />
            <Typography
              variant="body1"
              component="p"
              sx={{
                fontWeight: 700,
                fontSize: "32px",
                color: "#F4FDFF",
                fontFamily: "Lexend",
              }}
            >
              7,459,324
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "8px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 800,
              fontSize: "16px",
              color: "#F4FDFF",
              textAlign: "left",
              fontFamily: "Lexend",
            }}
          >
            Daily Jam Enhancer
          </Typography>

          <Box
            sx={{
              display: "flex",
              gap: "12px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#080F12",
                width: "50%",
                borderRadius: "12px",
                border: "2px solid #3A4A70",
                paddingX: "6px",
                paddingY: "12px",
              }}
            >
              <Box>
                <img src={Battery} alt="battery" />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 500,
                    color: "#F4FDFF",
                    fontFamily: "Lexend",
                  }}
                >
                  Full Battery
                </Typography>

                <Typography
                  sx={{
                    color: "#F4FDFF",
                    fontSize: "15px",
                    fontFamily: "Lexend",
                  }}
                >
                  3/3 available
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                // justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#080F12",
                width: "50%",
                borderRadius: "12px",
                border: "2px solid #3A4A70",
                paddingX: "6px",
                paddingY: "12px",
              }}
            >
              <Box>
                <img src={BassDrum} alt="BassDrum" />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 500,
                    color: "#F4FDFF",
                    fontFamily: "Lexend",
                  }}
                >
                  Fast Beat
                </Typography>

                <Typography
                  sx={{
                    color: "#F4FDFF",
                    fontSize: "15px",
                    fontFamily: "Lexend",
                  }}
                >
                  3/3 available
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          <Typography
            sx={{
              color: "#F4FDFF",
              fontWeight: 800,
              fontSize: "16px",
              fontFamily: "Lexend",
            }}
          >
            Amplifier:
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              color: "black",
              border: "2px solid #3A4A70",
              paddingX: "14px",
              paddingY: "5px",
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "13px",
              }}
            >
              <img src={Tabla} alt="Tabla" width={38} height={38} />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  component="p"
                  variant="body1"
                  sx={{
                    color: "#F4FDFF",
                    fontFamily: "Lexend",
                  }}
                >
                  Double your Beat
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "2px",
                  }}
                >
                  <img src={Coin} alt="Coin" width={18} height={18} />
                  <Typography
                    sx={{
                      color: "#F4FDFF",
                      fontWeight: 700,
                      fontSize: "15px",
                      fontFamily: "Lexend",
                    }}
                  >
                    12000 | <span style={{ color: "#F1E38A" }}>Level 8</span>
                  </Typography>
                </Box>
              </Box>
            </Box>

            <img src={Arrow} alt="Arrow" />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              color: "black",
              border: "2px solid #3A4A70",
              paddingX: "14px",
              paddingY: "5px",
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "13px",
              }}
            >
              <img src={PeakBattery} alt="PeakBattery" width={38} height={38} />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  component="p"
                  variant="body1"
                  sx={{
                    color: "#F4FDFF",
                    fontFamily: "Lexend",
                  }}
                >
                  Beat Peak
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "2px",
                  }}
                >
                  <img src={Coin} alt="Coin" width={18} height={18} />
                  <Typography
                    sx={{
                      color: "#F4FDFF",
                      fontWeight: 700,
                      fontSize: "15px",
                      fontFamily: "Lexend",
                    }}
                  >
                    12000 | <span style={{ color: "#F1E38A" }}>Level 4</span>
                  </Typography>
                </Box>
              </Box>
            </Box>

            <img src={Arrow} alt="Arrow" />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              color: "black",
              border: "2px solid #3A4A70",
              paddingX: "14px",
              paddingY: "5px",
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "13px",
              }}
            >
              <img
                src={CarCharger}
                alt="CarCharger"
                width={32}
                height={32}
                style={{ padding: "3px" }}
              />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  component="p"
                  variant="body1"
                  sx={{
                    color: "#F4FDFF",
                    fontFamily: "Lexend",
                  }}
                >
                  Rapid Reload
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "2px",
                  }}
                >
                  <img src={Coin} alt="Coin" width={18} height={18} />
                  <Typography
                    sx={{
                      color: "#F4FDFF",
                      fontWeight: 700,
                      fontSize: "15px",
                      fontFamily: "Lexend",
                    }}
                  >
                    12000 | <span style={{ color: "#F1E38A" }}>Level 2</span>
                  </Typography>
                </Box>
              </Box>
            </Box>

            <img src={Arrow} alt="Arrow" />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              color: "black",
              border: "2px solid #3A4A70",
              paddingX: "14px",
              paddingY: "5px",
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "13px",
              }}
            >
              <img src={LongDrum} alt="LongDrum" width={38} height={38} />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  component="p"
                  variant="body1"
                  sx={{
                    color: "#F4FDFF",
                    fontFamily: "Lexend",
                  }}
                >
                  My Drummer
                </Typography>

                <Box
                  sx={{
                    display: "flex",

                    alignItems: "center",
                    gap: "2px",
                  }}
                >
                  <img src={Coin} alt="Coin" width={18} height={18} />
                  <Typography
                    sx={{
                      color: "#F4FDFF",
                      fontWeight: 700,
                      fontSize: "15px",
                      fontFamily: "Lexend",
                    }}
                  >
                    12000
                  </Typography>
                </Box>
              </Box>
            </Box>

            <img src={Arrow} alt="Arrow" />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Boost;
