import { Box } from "@mui/material";
import React from "react";
import StatsSvg from "../images/Stats.svg";
import { Typography } from "@mui/material";
const Stats = () => {
  return (
    <div style={{ height: "100vh", padding: "24px" }}>
      <Box
        sx={{
          // Border
          // width: "100%", // Width in pixels
          margin: "auto",
          marginTop: "33px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "14px",
          paddingY: "36px",
          paddingX: "64px",
          border: "1px solid #3A4A70",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img src={StatsSvg} alt="Stats.svg" />
          <Typography
            variant="body1"
            component="p"
            sx={{
              margin: 0,
              color: "white",
              fontWeight: 700,
              fontSize: "1.25rem",
              fontFamily: "Lexend",
            }}
          >
            Stats
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
            textAlign: "center",
            border: "1px solid #3A4A70",
            borderRadius: "10px",
            paddingX: "15px",
            paddingY: "10px",
            minWidth: "200px",
            width: "100%",
            bgcolor: "black",
          }}
        >
          <Typography
            variant="body1"
            component="p"
            sx={{
              color: "#F4FDFF",
              fontWeight: 700,
              fontSize: "13px",
              fontFamily: "Lexend",
            }}
          >
            Total Collective Balance
          </Typography>
          <Typography
            component="p"
            sx={{
              color: "#F1E38A",
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: "25px",
              fontFamily: "Lexend",
            }}
          >
            256,345,422, 500
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
            textAlign: "center",
            borderRadius: "10px",
            paddingX: "15px",
            paddingY: "10px",
            minWidth: "200px",
            width: "100%",
            bgcolor: "black",
            border: "1px solid #3A4A70",
          }}
        >
          <Typography
            variant="body1"
            component="p"
            sx={{
              color: "#F4FDFF",
              fontWeight: 700,
              fontSize: "13px",
              fontFamily: "Lexend",
            }}
          >
            Total Drummer
          </Typography>
          <Typography
            component="p"
            sx={{
              color: "#F1E38A",
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: "25px",
              fontFamily: "Lexend",
            }}
          >
            6,345,422
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
            textAlign: "center",
            border: "1px solid #3A4A70",
            borderRadius: "10px",
            paddingX: "15px",
            paddingY: "10px",
            minWidth: "200px",
            width: "100%",
            bgcolor: "black",
          }}
        >
          <Typography
            variant="body1"
            component="p"
            sx={{
              color: "#F4FDFF",
              fontWeight: 700,
              fontSize: "13px",
              fontFamily: "Lexend",
            }}
          >
            Daily Drummers
          </Typography>
          <Typography
            component="p"
            sx={{
              color: "#F1E38A",
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: "25px",
              fontFamily: "Lexend",
            }}
          >
            256,345
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
            textAlign: "center",
            border: "1px solid #3A4A70",
            borderRadius: "10px",
            paddingX: "15px",
            paddingY: "10px",
            minWidth: "200px",
            width: "100%",
            bgcolor: "black",
          }}
        >
          <Typography
            variant="body1"
            component="p"
            sx={{
              color: "#F4FDFF",
              fontWeight: 700,
              fontSize: "13px",
              fontFamily: "Lexend",
            }}
          >
            Online Drummers
          </Typography>
          <Typography
            component="p"
            sx={{
              color: "#F1E38A",
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: "25px",
              fontFamily: "Lexend",
            }}
          >
            22, 500
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default Stats;
